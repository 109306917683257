import NextErrorComponent from 'next/error';
import FallbackUI from 'jiraaf-ui/molecules/ErrorBoundary/FallbackUI';

const MyError = ({ statusCode }) => {
    return <FallbackUI statusCode={statusCode} />;
};

MyError.getInitialProps = async (context) => {
    const errorInitialProps = await NextErrorComponent.getInitialProps(context);

    const { res, err } = context;

    return {
        errorData: err,
        statusCode: res?.statusCode,
        ...errorInitialProps,
    };
};

export default MyError;
